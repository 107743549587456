// Importando funciones de funciones.js
import { 
    llenarCardsBlog, llenarPageBlog, cargarPrecios, mostrarDetallesBlog, getParameterByName, iniciaSlider,
    navBackground, next_fondo, actBanner, nextBanner, updateActiveClass, llena_bannerspub, updateMessage, cycleMessages,
    reproduce_video_youtube, reproduce_video_modulo, reproduce_testimonio, activa_Transmision, info_Software,
    cargarCategoriasYLecciones, accionClickLeccion, apertura
} from './funciones.js';    

(function() {
    'use strict';


    // ? VARIABLES 
    var idSoftware = 2; 

    let heros = [];      

    // Obtener todos los enlaces del menú en un array
    const menuLinks = Array.from(document.querySelectorAll('.nav_links'));    

    const messages = [
        {
            title: "La Solución Todo-en-Uno Que Has Estado Buscando",
            description: "Desde la gestión de comandas hasta el control de inventario, BDKREST lo tiene todo. Elimina la necesidad de múltiples herramientas y centraliza tu operación."
        },
        {
            title: "Máxima Eficiencia, Mínimo Esfuerzo", 
            description: "Automatiza tareas rutinarias, minimiza errores y mejora la productividad con BDKREST."
        },
        {
            title: "¿Por Qué Elegir BDKREST?",
            description: "Con nuestra tecnología avanzada y soporte inigualable, hacer crecer tu restaurante nunca ha sido tan fácil."
        },
        {
            title: "Transforma Tu Restaurante Hoy",
            description: "Únete a los cientos de restaurantes que han elevado su negocio con BDKREST. No te quedes atrás."
        }
    ];

    let currentIndex = 0;
    const titleElement = document.getElementById('message-title');
    const descriptionElement = document.getElementById('message-description');
    const containerElement = document.getElementById('message-container');
    const indicators = document.querySelectorAll('#indicators span');


    document.addEventListener('DOMContentLoaded', function(){


        //! MENU LATERAL EN VERSIONES TABLET Y MOVIL

        const botMenu = document.getElementById('botMenu');

        if (botMenu) {
            botMenu.addEventListener('click', function(event) {
                event.preventDefault();  // Evitar que la página se mueva al inicio
                apertura();  // Llamar a la función para abrir el menú
            });
        }

        // ! CÓDIGO PARA QUE CUANDO SE DE CLICK EN UN ENLACE DEL MENU CUANDO ES VERSIÓN MÓVIL, SE CIERRE.
        const menu = document.getElementById('navPrincipal');
        const enlacesMenu = document.querySelectorAll('#navPrincipal .nav_links');
        
        enlacesMenu.forEach((enlace) => {
            enlace.addEventListener('click', () => {
                // Verifica si el menú tiene la clase 'menu2'
                if (menu.classList.contains('menu2')) {
                    // Si la tiene, la eliminamos
                    menu.classList.remove('menu2');
                }
            });
        });    
        
        // ! Escuchar el evento de desplazamiento y actualizar la clase 'nav_active'
        // * La cual me sirve para marcar en que sección de la página me encuentro.

        window.addEventListener('scroll', () => updateActiveClass(menuLinks));        

        // ! Actualizar la clase 'nav_active' cuando la página se carga
        window.addEventListener('load', () => updateActiveClass(menuLinks));        
        

        //! LLENAR CARDS DEL BLOG INFORMATIVO DE LA PÁGINA PRINCIPAL
        const div_blog = document.getElementById('contenedor-cards-blog');

        if (div_blog !== null) {
            llenarCardsBlog(div_blog, idSoftware); 
        };

        //! LLENAR CARDS DEL BLOG INFORMATIVO DE LA PÁGINA DEL BLOG
        const page_blog = document.getElementById('pagina-cards-blog');

        if (page_blog !== null) {
            llenarPageBlog(page_blog, idSoftware);  
        };

        iniciaSlider();

        //! Cargando los precios de las diferentes licencias

        const div_precios = document.getElementById('sec-precios');

        if (div_precios) {
            cargarPrecios(idSoftware); 
        }

        //! Llena la información de una noticia del BLOG
        const blogId = getParameterByName('id'); 
        if (blogId !== null) {
            mostrarDetallesBlog(blogId);
        }

        //! Carga el evento para aclarar u obscurecer el background del NAV

        window.addEventListener('scroll', function() {
            navBackground(); 
        })
        navBackground();

        //! Activa la función para hacer dinámico el fondo del hero de la pantalla, para cambiar entre cada imagen.

        if (document.getElementById("fondosHero")) {
            heros = document.querySelectorAll('.fondo_hero');
            setInterval(() => next_fondo(heros), 3000);
        }

        //! Visualizando los banners de publicidad
        const div_pub = document.getElementById('div_pub');

        if (div_pub !== null) {
            llena_bannerspub(div_pub);             
        };

        //! Visualizando mensajes informativos en la banda de color del hero
        if (containerElement !== null) {
            updateMessage(messages, titleElement, descriptionElement, indicators, containerElement, currentIndex);
            cycleMessages(messages, titleElement, descriptionElement, indicators, containerElement, currentIndex);
        }

        //! Monitoreando los enlaces del botón PLAY para reproducir vídeos de Youtube en el DOM
        const playLink = document.querySelector('.play');
        if (playLink) {
            document.querySelectorAll('.play').forEach(link => {
                link.addEventListener('click', reproduce_video_youtube);
            }); 
        }

        //! Reproduce el vídeo de una reseña
        if ($('.play2').length && $('#ventanaVideo').length && $('#iframeVideo').length) {
            

            $('ul.slide-area').on('click', '.play2', function(event) {            
                event.preventDefault();

                reproduce_testimonio(this);
            });
    
            $('#ventanaVideo').on('hidden.bs.modal', function () {
                $('#iframeVideo').attr('src', ''); 
            });
        }

        //! Monitoreando los enlaces del botón PLAY para reproducir vídeos de Youtube en el DOM
        const playModulo = document.querySelector('.play-modulo');
        if (playModulo) {
            document.querySelectorAll('.play-modulo').forEach(link => {
                link.addEventListener('click', reproduce_video_modulo);
            }); 
        }

        //! Funcionalidad para abrir y cerrar las Pregunta Frecuentes

        // Asegurar que todos los paneles están correctamente inicializados
        $('.accordion-content').hide(); // Oculta todos los paneles
        $('.accordion-button').attr('aria-expanded', 'false'); // Establece todos como no expandidos

        $('.accordion-button').on('click', function() {
            var isExpanded = $(this).attr('aria-expanded') === 'true';
            $(this).attr('aria-expanded', !isExpanded);
            $(this).next('.accordion-content').slideToggle();

            // Cierra los otros elementos si solo uno debe estar abierto a la vez
            if (!isExpanded) {
                $('.accordion-content').not($(this).next()).slideUp();
                $('.accordion-button').not(this).attr('aria-expanded', 'false');
            }
        });

        //! Mantiene actualizado el año en la línea de los Derechos de Autor (PIE DE PÁGINA)
        document.addEventListener('contentIncluded', () => {
            const year = new Date().getFullYear(); // Obtiene el año actual
            const yearElement = document.getElementById('current-year');
            if (yearElement) {
                yearElement.textContent = year; // Establece el año en el elemento span
            }
        });

        //! Activando las Transmisiones en Vivo
        if (document.getElementById("secTransmisiones")) {

            activa_Transmision();
        
        };

        //! Obteniendo el link de descarga del software        
        const pasosDescarga = document.getElementById('pasos-para-descargar');

        if (pasosDescarga) {        

            info_Software();
        }

        //! Obteniendo el listado de lecciones para la página de Capacitación
        const capacitacionSection = document.getElementById('capacitacion');
        
        if (capacitacionSection) {
            
            // Llamar a la función para cargar las categorías y las lecciones
            cargarCategoriasYLecciones();
                    
        }

        //! Enviando el formulario de contacto
        const contactForm = document.getElementById('contact-form');

        if(contactForm) {

            document.getElementById("contact-form").addEventListener("submit", function(event) {
                event.preventDefault();
            
                const token = grecaptcha.getResponse(); // Obtiene el token del reCAPTCHA
                if (!token) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Por favor, verifica que no eres un robot.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar'
                    });
                    return;
                }
            
                fetch('/scripts/verify-recaptcha.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: new URLSearchParams({ token: token })
                })
                .then(response => response.json())
                .then(data => {

                    if (data.success) {

                        // console.log('Validación exitosa:', data);

                        // Captura los valores de los campos del formulario

                        const userName = document.getElementById("user_name").value;
                        const userPhone = document.getElementById("user_phone").value;
                        const userEmail = document.getElementById("user_email").value;
                        const userBusiness = document.getElementById("user_business").value;
                        const userMessage = document.getElementById("user_message").value;

                        // Configura los parámetros para enviar con EmailJS

                        const templateParams = {
                            user_name: userName,
                            user_phone: userPhone,
                            user_email: userEmail,
                            user_business: userBusiness,
                            user_message: userMessage
                        };

                        // Usa EmailJS para enviar el correo
                        emailjs.send("service_5jaisje", "template_q8m0x7f", templateParams)
                        .then(function(response) {

                            Swal.fire({
                                title: 'Correo Envíado',
                                text: 'Tu correo fue envíado correctamente. En breve recibirás noticias.',
                                icon: 'success',
                                confirmButtonText: 'Cerrar'
                            });

                            document.getElementById("contact-form").reset(); 
                            window.location.href = 'graciasporcontactarnos.html';


                        }, function(error) {

                            Swal.fire({
                                title: 'Error',
                                text: 'Hubo un error al enviar el mensaje. Inténtalo nuevamente.',
                                icon: 'error',
                                confirmButtonText: 'Cerrar'
                            });

                            console.error("Error:", error);

                        });

                    } else {
                        Swal.fire({
                            title: 'Error en la verificación',
                            text: data.message || 'No se pudo verificar tu solicitud. Inténtalo nuevamente.',
                            icon: 'error',
                            confirmButtonText: 'Cerrar'
                        });
                        console.error('Detalles:', data);
                    }
                })
                .catch(error => {
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al validar el reCAPTCHA. Inténtalo nuevamente.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar'
                    });
                    console.error('Error en la solicitud:', error);
                });
            });
                        
        }

    });


})();